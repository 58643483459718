
import { Options, mixins } from "vue-class-component";
import Join from "@/components/Join/index.vue";
import Panelist from "@/mixins/Panelist";

@Options({
  components: {
    Join,
  },
})
export default class SurveyPaused extends mixins(Panelist) {}
